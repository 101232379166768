import * as actions from "../actions";

const appendChar = (current, char) => {
  if (char === "{backspace}") {
    return  current.substring(0, current.length - 1);
  }
  return current + char;
}
const initialState = {search: "", lastChar: undefined, technician: null, availableTechnicians: null};

const search = (state = initialState, action) => {
  switch (action.type) {
    case actions.RESET_SEARCH: 
      return {...state, ...initialState};

    case actions.TOUCH_KEYBOARD_INPUT:
      return {...state, lastChar: action.character, search: appendChar(state.search, action.character)};

    case actions.KEYBOARD_INPUT:
        return {...state, lastChar: action.character, search: action.text};

    case actions.TECHNICIAN_FETCHED:
      const technician = action.technicians.length > 0 && action.technicians[0];
      return {...state, 
        technician: technician && {...technician.technician, workshopBay: technician.workshopBay, nextAvailableSlot: technician.nextAvailableSlot, selectedAvailableTechnician: false}};
    
    case actions.FETCH_ERROR:
      return {...state, technician: {fetchError: true}};

    case actions.AVAILABLE_TECHNICIANS_FETCHED:
      const availableTechnicians = action.availableTechnicians && action.availableTechnicians.length > 0;
      return {...state, availableTechnicians: availableTechnicians && action.availableTechnicians};

    case actions.SEARCH_AVAILABLE_TECHNICIANS_ERROR:
      const searchAvailableTechniciansError = action.error && action.error[0] && action.error[0].ErrorMessage;
      return {...state, availableTechnicians: {fetchError: true, errorMessage: searchAvailableTechniciansError}};

    case actions.AVAILABLE_TECHNICIAN_SELECTED:
      const selectedTechnician = action.selectedTechnician;
      return {...state, 
        technician: selectedTechnician && {...selectedTechnician.technician, workshopBay: selectedTechnician.workshopBay, nextAvailableSlot: selectedTechnician.nextAvailableSlot, selectedAvailableTechnician: true}};

    default:
      return state;
  }
};

export default search;