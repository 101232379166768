import React from "react";
import "./index.css";
import { useTranslation } from 'react-i18next';


const Header = () => {
    // eslint-disable-next-line
    const [t, i18n] = useTranslation();
    return (
        <div className="welcome">
            <div className="findTechnician">
                <h1>{t('welcome.search')}</h1>
            </div>
            <div>
                <h2>{t('welcome.regplate')}</h2>
            </div>
        </div>
    ); 
};
export default Header;