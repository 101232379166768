import React from "react";
import "./index.css";
import NotFound from "./notFound"
import FetchError from "./fetchError"
import TechnicianInfo from "./technicianInfo"

const Technician = (props) => {
    return (
        <div className="technicianContainer">
            <FetchError technician={props.technician} />
            <NotFound technician={props.technician} />
            <TechnicianInfo technician={props.technician} />
        </div>
        ); 
};
export default Technician;