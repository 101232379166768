import React from "react";
import { useTranslation } from 'react-i18next';
import ReturnToStart from "../returnToStart"

const NotFound = (props) => {
    const t = useTranslation()[0];

    if (props.technician) {
        return null;
    }
    return (
        <div>
            <div className="technicianNotFoundMessage">
                <h1>{t("technician.notFound")}</h1>
            </div>
            <ReturnToStart />
        </div>

        ); 
};
export default NotFound;