import React from "react";
import "./index.css";
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useLocalStorage } from '@rehooks/local-storage';
import { useHistory } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import * as constants from "./constants";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector  } from 'react-redux'

const BranchSetup = () => {
    const [storedTenant, writeTenantToStorage] = useLocalStorage(constants.TENANT)
    const [storedBranch, writeBranchToStorage] = useLocalStorage(constants.BRANCH)
    const [storedLanguage, writeLanguageToStorage] = useLocalStorage(constants.DEFAULT_LANGUAGE)
    // eslint-disable-next-line
    const [t, i18n] = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [branchName, setBranchName] = React.useState(storedBranch || t("setup.branchInstruction"));
    const [tenantId, setTenantId] = React.useState(storedTenant || t("setup.tenantInstruction"));
    const availableLanguages = useSelector(state => state.language.available)
    const fallbackLanguage = () => {
        return availableLanguages.find(f => f.fallback);
    }
    const [language, setLanguage] = React.useState(storedLanguage || fallbackLanguage().language);
    const history = useHistory();

    const handleTextChange = event => {
        if (event.target.name === constants.BRANCH){
            setBranchName(event.target.value);
        } else if (event.target.name === constants.TENANT){ 
            setTenantId(event.target.value);
        } else {
            setLanguage(event.target.value);
        }
      };
      
    const handleSaveClose = () => {
      setOpen(false);
      history.push("/")
    };    

    const save = () => {
        t("setup.tenantInstruction") !== tenantId && writeTenantToStorage(tenantId);
        t("setup.branchInstruction") !== branchName && writeBranchToStorage(branchName);
        writeLanguageToStorage(language)
        setOpen(true);
    }

    const hasEnteredData = () => {
        return hasEnteredBranch() && hasEnteredTenant();
    }

    const hasEnteredTenant = () => {
        return t("setup.tenantInstruction") !== tenantId;
    }

    const hasEnteredBranch = () => {
        return t("setup.branchInstruction") !== branchName;
    }

    return (
        <div className="setupContainer">
            <div className="setupTitle">
                <h1>{t("setup.title")}</h1>
            </div>
            <div>
                <TextField 
                    onChange={handleTextChange} 
                    name={constants.TENANT}
                    placeholder={tenantId} 
                    defaultValue={storedTenant}
                    label={t("setup.tenant")} 
                    variant="outlined" />
            </div>
            <div>
                <TextField 
                    onChange={handleTextChange} 
                    name={constants.BRANCH}
                    defaultValue={storedBranch}
                    placeholder={branchName} 
                    label={t("setup.branchName")}  
                    variant="outlined" />
            </div>
            <div>
                <FormControl className="setupLanguage" variant="outlined" >
                    <InputLabel id="demo-simple-select-outlined-label">
                    {t("setup.language")}
                    </InputLabel>
                    <Select
                        onChange={handleTextChange}
                        value={language}>
                    {
                        availableLanguages.map(l => 
                            { 
                                return <MenuItem key={l.language} value={l.language}>{l.name}</MenuItem>
                            })
                    }
                    </Select>
                </FormControl>
            </div>
            <div className="setupSave">
                <Button 
                    onClick={save} 
                    disabled={!hasEnteredData()}
                    variant="outlined">{t("setup.save")}
                </Button>
            </div>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleSaveClose}
                message={t("setup.saved")}
                />
        </div>
        ); 
};
export default BranchSetup;