import * as actions from "../actions";
import * as constants from "./constants";

const fallbackSubscriptions = () => {
  return constants.subscriptions;
}

const subscriptions = (state = {
                        subscriptions: fallbackSubscriptions(),
                        hasSubscriptionToSearchAvailableTechnicians: false
                      }, action) => {
  switch (action.type) {
    case actions.SUBSCRIPTION_FETCHED:
      return {...state, 
        subscriptions: action.subscriptions,
        hasSubscriptionToSearchAvailableTechnicians: action.subscriptions !== false && action.subscriptions.includes("search available technicians")};
    default:
      return state;
  }
};

export default subscriptions;