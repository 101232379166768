import React, { useRef } from "react";
import { useDispatch  } from 'react-redux'
import SimpleKeyboard from "react-simple-keyboard";
import "./index.css";
import * as actions from "../../actions";
import "react-simple-keyboard/build/css/index.css";
import { useSelector } from 'react-redux'

const Keyboard = () => {
    const keyboard = useRef();
    const dispatch = useDispatch()
    const currentLayout = useSelector(state => state.language.layout)
    const currentDisplay = useSelector(state => state.language.display)

    const onKeyPress = button => {
        console.log("Button pressed", button);
        dispatch(actions.input(button));
    };

    return (
        <div className="keyboardContainer">
            <SimpleKeyboard
                layout={currentLayout}
                keyboardRef={r => (keyboard.current = r)}
                layoutName="default"
                display={currentDisplay}
                onKeyPress={button => onKeyPress(button)} />
        </div>
    ); 
};
export default Keyboard;