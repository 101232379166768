import React from "react";
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import ReturnToStart from "../returnToStart"

const TechnicianPresentation = (props) => {
    const t = useTranslation()[0];
    return (
            <div className="testing">
                    <div className="technicianWelcome">
                        <h1 >{t("technician.welcome")}</h1>
                    </div>
                    <div className="technicianDisplayContainer">
                        <div className="technicianCarKey">
                            <h3>{props.technician.selectedAvailableTechnician ? t("technician.followReference") : t("technician.leaveCarkey")}</h3>
                        </div>
                        <div className="technicianInfoItem">
                            <img alt="Technician" className="technicianImage" src={props.technician.imageUri || "/images/anonymous.png"}></img>
                        </div>
                        <div className="technicianInfoItem">
                            <div className="technicianName" >{props.technician.name}</div>
                            <div>{t("technician.jobTitle")}</div>
                        </div>
                        <div className="technicianInfoItem">
                            <div>{t("technician.bay")}</div>
                            <div><Avatar className="technicianBay">{props.technician.workshopBay.areaName}</Avatar></div>
                        </div>
                    </div>
                    <ReturnToStart />
            </div>
        ); 
};
export default TechnicianPresentation;