import React, {useEffect } from "react";
import { useTranslation } from 'react-i18next';
import * as actions from "../../actions";
import { useHistory } from "react-router-dom";
import { useDispatch  } from 'react-redux'
import Button from '@material-ui/core/Button';
import ReplayIcon from '@material-ui/icons/Replay';

const ReturnToStart = (props) => {
    const t = useTranslation()[0];
    const history = useHistory();
    const dispatch = useDispatch()

    const redirectToStart = () => {
        // if (history.location.pathname !== "/technician") return
        history.push("/")
        dispatch(actions.resetSearch());
    };

    const waitAndRedirect = () => {
        setTimeout(() => {
                redirectToStart();
            
          }, 60000);
    }

    useEffect(() => {
        waitAndRedirect();
    })

    return (
        <div className="tehcnicianStartOver"> 
            <Button
                onClick={redirectToStart}
                variant="outlined"
                startIcon={<ReplayIcon />}>
                    {t("technician.startAgain")}
            </Button>
        </div>            
    ); 
};
export default ReturnToStart;