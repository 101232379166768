import React from 'react';
import './App.css';
import Keyboard from "./components/keyboard"
import LanguageSelector from "./components/languageSelector"
import SearchAvailableTechnicians from "./components/searchAvailableTechnicians"
import Search from "./components/search"
import Header from "./components/header"
import BranchSetup from "./components/branchSetup"
import Technician from "./components/technician"
import AvailableTechnicianSummary from "./components/availableTechnicianSummary"
import BranchSetupChecker from "./components/branchSetup/checker"
import ReturnToStart from "./components/returnToStart"
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { useLocalStorage } from '@rehooks/local-storage';
import * as constants from "./components/branchSetup/constants";
import { useSelector } from 'react-redux'
import BgImage from "./components/background-image"
import * as actions from "./actions";
import { useDispatch } from 'react-redux'

function App() {
  const dispatch = useDispatch()

  const [storedTenant] = useLocalStorage(constants.TENANT)
  const [storedBranch] = useLocalStorage(constants.BRANCH)
  const technician = useSelector(state => state.search.technician)
  const availableTechnicians = useSelector(state => state.search.availableTechnicians)
  const bgImageNumber = useSelector(state => state.language.background)
  
  const subscriptions = async () => {
    var success = await dispatch(actions.getSubscriptions(storedTenant));
    return success;
  };

  return (
    <Router>
      <div className="app">
        <div className="app-logo">
          <img src="./images/volvo-logo.png" className="logo" alt="VOLVO" />
        </div>
        <BgImage imageNumber={bgImageNumber} />
        <Switch>
          <Route path="/" exact>
            <div className="app-language">
              <LanguageSelector />
            </div>
            <div className="app-body">
              <Header />
              <Search tenantId={storedTenant} branchName={storedBranch} />
              <Keyboard />
            </div>
            <BranchSetupChecker />
            <div className="app-available-time">
              <SearchAvailableTechnicians subscriptions={subscriptions()} tenantId={storedTenant} branchName={storedBranch} />
            </div>
          </Route>
          <Route path="/setup" exact>
            <BranchSetup />
          </Route>
          <Route path="/technician" exact>
            <Technician technician={technician} />
          </Route>
          <Route path="/availableTechnicians" exact>
            <div className="app-body">
              <AvailableTechnicianSummary availableTechnicians={availableTechnicians} />
            </div>
            <div className="app-return-to-start-footer">
              <ReturnToStart />
            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
