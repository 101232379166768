export const TOUCH_KEYBOARD_INPUT = 'TOUCH_KEYBOARD_INPUT'
export const KEYBOARD_INPUT = 'KEYBOARD_INPUT'
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE'
export const FETCH_SUBSCRIPTION = 'FETCH_SUBSCRIPTION'
export const SEARCH_TECHNICIAN = 'SEARCH_TECHNICIAN'
export const SEARCH_AVAILABLE_TECHNICIANS = 'SEARCH_AVAILABLE_TECHNICIANS'
export const SUBSCRIPTION_FETCHED = 'SUBSCRIPTION_FETCHED'
export const GET_SUBSCRIPTIONS_ERROR = 'GET_SUBSCRIPTIONS_ERROR'
export const TECHNICIAN_FETCHED = 'TECHNICIAN_FETCHED'
export const AVAILABLE_TECHNICIANS_FETCHED = 'AVAILABLE_TECHNICIANS_FETCHED'
export const FETCH_ERROR = 'FETCH_ERROR'
export const SEARCH_AVAILABLE_TECHNICIANS_ERROR = 'SEARCH_AVAILABLE_TECHNICIAN_ERROR'
export const RESET_SEARCH = 'RESET_SEARCH'
export const AVAILABLE_TECHNICIAN_SELECTED = 'AVAILABLE_TECHNICIAN_SELECTED'


export const input = (character) => ({
  type: TOUCH_KEYBOARD_INPUT,
  character
})

export const keyboardInput = (text) => ({
  type: KEYBOARD_INPUT,
  text
})

export const resetSearch = () => ({
  type: RESET_SEARCH,
})

export const switchLanguage = (language) => ({
  type: SWITCH_LANGUAGE,
  language
})

const fetchSubscriptions = () => ({
  type: FETCH_SUBSCRIPTION,
})

const subscriptionsFetched = (subscriptions) => ({
  type: SUBSCRIPTION_FETCHED,
  subscriptions
})

const fetchSubscriptionsError = (error) => ({
  type: GET_SUBSCRIPTIONS_ERROR,
  error
})

const fetchTechnician = () => ({
  type: SEARCH_TECHNICIAN,
})

const fetchAvailableTechnicians = () => ({
  type: SEARCH_AVAILABLE_TECHNICIANS,
})

const technicianFetched = (technicians) => ({
  type: TECHNICIAN_FETCHED,
  technicians
})

const fetchError = (error) => ({
  type: FETCH_ERROR,
  error
})

const availableTechniciansFetched = (availableTechnicians) => ({
  type: AVAILABLE_TECHNICIANS_FETCHED,
  availableTechnicians
})

const availableTechnicianSelected = (selectedTechnician) => ({
  type: AVAILABLE_TECHNICIAN_SELECTED,
  selectedTechnician
})

const searchAvailableTechniciansError = (error) => ({
  type: SEARCH_AVAILABLE_TECHNICIANS_ERROR,
  error
})

export const getSubscriptions = (tenantId) => {
  return async function (dispatch) {
    dispatch(fetchSubscriptions());

    var url = 
    // new URL(`http://localhost:56783/service-guide/get-subscriptions`); 
    // "http://localhost:56783/api/service-guide/get-subscriptions?Tenant=SE14T1"
    new URL(`${process.env.REACT_APP_API_URL}/service-guide/get-subscriptions`)
    url.searchParams.append("Tenant", tenantId)

    try {
      const response = await fetch(url)
      if (response.ok) {
        const result = await response.json()
        
        if (result.length > 0) {
          dispatch(subscriptionsFetched(result))
          return result;
        }
        return false;
      }
    }
    catch (e) {
      dispatch(fetchSubscriptionsError(e))
    }
    return false;
  }
}

export const findTechnician = (tenantId, branchName, regNo) => {
  return async function (dispatch) {
    dispatch(fetchTechnician());

    var url = new URL(`${process.env.REACT_APP_API_URL}/service-guide/find-booking`)
    url.searchParams.append("Tenant", tenantId)
    url.searchParams.append("Branch", branchName)
    url.searchParams.append("RegistrationNumber", regNo)

    try {
      const response = await fetch(url)
      if (response.ok) {
        const result = await response.json()
        if (result.errors.length === 0) {
          dispatch(technicianFetched(result.personServiceTechnicians))
          return true;
        }
        dispatch(fetchError(result.errors))
        return false;
      }
    }
    catch (e) {
      dispatch(fetchError(e))
    }
    return false;
  }
}

export const searchAvailableTechnicians = (tenantId, branchName) => {

  return async function (dispatch) {
    dispatch(fetchAvailableTechnicians());

    var url = new URL(`${process.env.REACT_APP_API_URL}/service-guide/search-available-technicians`)
    url.searchParams.append("Tenant", tenantId)
    url.searchParams.append("Branch", branchName)
    url.searchParams.append("RegistrationNumber", null)

    try {
      const response = await fetch(url)
      if (response.ok) {
        const result = await response.json()
        if (result.errors.length === 0) {
          dispatch(availableTechniciansFetched(result.personServiceTechnicians))
          return true;
        }
        dispatch(searchAvailableTechniciansError(result.errors))
        return false;
      }
    }
    catch (e) {
      dispatch(searchAvailableTechniciansError(e))
    }
    return false;
  }
}

export const selectAvailableTechnician = (availableTechnician) => {
  return async function (dispatch) {
    dispatch(availableTechnicianSelected(availableTechnician));
    return true;
  }
}
