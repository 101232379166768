import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import * as actions from "../../actions";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default function ComplexGrid(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslation()[0];

  if (!props.technician || !props.nextAvailableSlot) {
    return null;
  }

  const hasTechnicianImage = () => {
    return props.technician.imageUri && props.technician.imageUri.length > 0
  };

  const hasAvailableTime = () => {
    return props.nextAvailableSlot && props.nextAvailableSlot.date
  };

  if(!hasAvailableTime()){
    return null;
  }

  const findTechnician = async () => {
    var success = await dispatch(actions.selectAvailableTechnician(props));
        if (!success) {
            console.error(success)
        }
    history.push("/technician")
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item>
            <Grid container>
              <Grid item>
                <ButtonBase className={classes.image}>
                  <img className={classes.img} alt="complex"
                    src={hasTechnicianImage() ? props.technician.imageUri : "/images/anonymous.png"} />
                </ButtonBase>
                <Typography align="center">{props.workshopBay.areaName}</Typography>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2} >
              <Grid item xs>
                <Typography gutterBottom variant="h5" align="left">{props.technician.name}</Typography>
                <Typography variant="body2" color="textSecondary" align="left">{t("availableTechnician.telephone")} {props.technician.phone}</Typography>
                <Typography variant="h6" gutterBottom align="left">{t("availableTechnician.availableDate")} {props.nextAvailableSlot.date}</Typography>
                <button onClick={() => findTechnician()} className="searchButton MuiButton-fullWidth" value="OK">{t("availableTechnician.visaPlats")}</button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}