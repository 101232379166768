export const keyboardLayoutSe =  {
    'default': [
   '1 2 3 4 5 6 7 8 9 0 {backspace}',
   'Q W E R T Y U I O P Å',
   'A S D F G H J K L Ö Ä',
   'Z X C V B N M'
 ],
 };
 
 export const keyboardLayoutNo =  {
   'default': [
  '1 2 3 4 5 6 7 8 9 0 {backspace}',
  'Q W E R T Y U I O P Å',
  'A S D F G H J K L Ø Æ',
  'Z X C V B N M'
 ],
 };
 
 export const keyboardLayoutEn =  {
   'default': [
  '1 2 3 4 5 6 7 8 9 0 {backspace}',
  'Q W E R T Y U I O P',
  'A S D F G H J K L',
  'Z X C V B N M'
 ],
 };
 
 export const keyboardLayoutDe =  {
   'default': [
  '1 2 3 4 5 6 7 8 9 0 ß {backspace}',
  'Q W E R T Z U I O P Ü',
  'A S D F G H J K L Ö Ä',
  'Y X C V B N M -'
 ],
 };
 
 export const keybooardDisplay =  {
   "{backspace}": "<svg class='backspace'><path d='M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z'></path></svg>",
 }

 export const availableLanguages = [
    {language: "en", name: "English", layout:  keyboardLayoutEn, fallback:true},
    {language: "no", name: "Norsk", layout: keyboardLayoutNo},
    {language: "se", name: "Svenska", layout: keyboardLayoutSe},
  ]

  export const subscriptions = []