import {useEffect } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';
import { useHistory } from "react-router-dom";
import * as constants from "./constants";

const BranchSetupChecker = () => {
    const [storedTenant] = useLocalStorage(constants.TENANT)
    const [storedBranch] = useLocalStorage(constants.BRANCH)
    const history = useHistory();

    const redirectToSetup = () => {
      history.push("/setup")
    };    

    const isBranchSetup = () => {
        return storedTenant && storedBranch;
    }

    useEffect(() => {
        !isBranchSetup() && redirectToSetup()
    })

    return (
        null
    ); 
};
export default BranchSetupChecker;