
import React from 'react';
import AvailableTechnicians from "./availableTechnicians"
import NotFound from "./notFound"

const AvailableTechnicianSummary = (props) => {
    return (
        <div>
            <AvailableTechnicians availableTechnicians={props.availableTechnicians} />
            <NotFound availableTechnicians={props.availableTechnicians}/>
        </div>
    );
};
export default AvailableTechnicianSummary;