import * as actions from "../actions";
import * as constants from "./constants";

const fallbackLanguage = () => {
  return constants.availableLanguages.find(f => f.fallback);
}

const keyboardLayoutFromLanguage = (lang) => {
  const language = constants.availableLanguages.find(l => l.language === lang);

  return language ? language.layout : fallbackLanguage().layout;
}

const lang = (state = {
                        language: null, 
                        available: constants.availableLanguages,  
                        display: constants.keybooardDisplay, 
                        layout: fallbackLanguage().layout,
                        background: 1
                      }, action) => {
  switch (action.type) {
    case actions.SWITCH_LANGUAGE:
      return {...state, 
        language: action.language, 
        display: constants.keybooardDisplay,
        layout: keyboardLayoutFromLanguage(action.language)};
    case actions.RESET_SEARCH: 
      return {...state, background: Math.floor(Math.random() * 6) + 1};        
    default:
      return state;
  }
};

export default lang;