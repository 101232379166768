import React from "react";
import { useDispatch } from 'react-redux'
import "./index.css";
import * as actions from "../../actions";
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

const Search = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const registrationNumber = useSelector(state => state.search.search)
    // eslint-disable-next-line
    const [t, i18n] = useTranslation();

    const findTechnician = async () => {
        var success = await dispatch(actions.findTechnician(props.tenantId, props.branchName, registrationNumber));
        if (!success) {
            console.error(success)
        }
        history.push("/technician")
    };

    const onChange = event => {
        dispatch(actions.keyboardInput(event.target.value.toUpperCase()));
    };

    const onKeyDown = event => {
        if (event.key === 'Enter') {
            findTechnician()
        }
    };

    return (
        <div className="searchContainer">
            <span className="regNumberInputContainer">
                <input autoFocus type="text" className="regNumberInput" value={registrationNumber} onKeyDown={onKeyDown} onChange={onChange} />
            </span>
            <span className="searchButtonContainer">
                <button onClick={() => findTechnician()} className="searchButton" value="OK"><KeyboardReturnIcon fontSize="small" /></button>
            </span>
        </div>
    );
};
export default Search; 