import React from "react";
import { useDispatch, useSelector  } from 'react-redux'
import { useTranslation } from 'react-i18next';
import * as reduxActions from "../../actions";
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import "./index.css";
import { useLocalStorage } from '@rehooks/local-storage';
import * as constants from "../branchSetup/constants";

const LanguageSelector = () => {
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const hidden = React.useState(false)[0];
    const storedLanguage = useLocalStorage(constants.DEFAULT_LANGUAGE)[0]
    const currentLanguage = useSelector(state => state.language.language)
    const availableLanguages = useSelector(state => state.language.available)
    let actions = availableLanguages.map(l => 
        { 
          return { 
            name: l.name,  
            language: l.language,
            icon: <img alt={l.name} className="flagIcon" src={`/icons/${l.language}.svg`} />
          }
        });

    const i18n = useTranslation()[1];
    const changeLanguage = lng => {
        dispatch(reduxActions.switchLanguage(lng));
        i18n.changeLanguage(lng);
        handleClose();
    };

    const currentLanguageIcon = () => {
        var action = actions.find((action) => action.language === currentLanguage);
        return action && action.icon
    }
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    if (!currentLanguage){
      changeLanguage(storedLanguage)
    }

    return (
        <div>
          <SpeedDial
            direction="down"
            ariaLabel="SpeedDial openIcon example"
            hidden={hidden}
            icon={currentLanguageIcon()}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
          >
            {actions.map(action => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen={true}
                onClick={() => changeLanguage(action.language)}
                />
            ))}
          </SpeedDial>
        </div>
      );    
};
export default LanguageSelector;