import React from "react";
import "./index.css";

const BgImage = (props) => {
    return (
        <div className="bg">
            <img src={`/images/background.png`} alt="" />
        </div>
    ); 
};
export default BgImage;