import React from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import * as actions from "../../actions";
import "./index.css";
import { useSelector } from 'react-redux'

const SearchAvailableTechnicians = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const t = useTranslation()[0];

  const searchAvailableTechnicians = async () => {
    var success = await dispatch(actions.searchAvailableTechnicians(props.tenantId, props.branchName));
    if (!success) {
      console.error(success)
    }
    history.push("/availableTechnicians")
  };

  const subscriptions = useSelector(state => state.subscriptions)
  const hasSubscriptionToSearchAvailableTechnicians = subscriptions.hasSubscriptionToSearchAvailableTechnicians;

  return (
    <div className={hasSubscriptionToSearchAvailableTechnicians ? '' : 'app-available-time-not-subscribed'}>
      <button onClick={() => searchAvailableTechnicians()} className="searchButton" value="OK">{t("welcome.searchAvailableTechnicians")}</button>
    </div>

  );
};
export default SearchAvailableTechnicians;