import React from "react";
import BasicTable from "./table"

const AvailableTechnicians = (props) => {
    if (!props.availableTechnicians || props.availableTechnicians.fetchError) {
        return null;
    }

    return (
        <div>
            <BasicTable {...props} />
        </div>

    );
};
export default AvailableTechnicians;