import React from "react";
import { useTranslation } from 'react-i18next';

const NotFound = (props) => {
    const t = useTranslation()[0];    

    if (props.availableTechnicians && props.availableTechnicians.fetchError && props.availableTechnicians.errorMessage) {
        return (
            <div>
                <div className="techniciansNotAvailableMessage">
                    <h1>{props.availableTechnicians.errorMessage}...</h1>
                </div>
            </div>
    
            ); 
    }

    if (props.availableTechnicians || !props.availableTechnicians.fetchError){
        return null;
    }

    return (
        <div>
            <div className="techniciansNotAvailableMessage">
                <h1>{t("availableTechnician.noTime")}</h1>
            </div>
        </div>

        ); 
};
export default NotFound;