import React from "react";
import Presentation from "./technicianIPresentation"
import Map from "./workbayMap"
import c from "classnames"

const TechnicanInfo = (props) => {
    const hasWorkshopMap = () => {
        return props.technician.workshopBay.imageUri && props.technician.workshopBay.imageUri.length > 0
    };

    if (!props.technician || props.technician.fetchError) {
        return null;
    }
    return (
        <div className={c("technicianInfoContainer", {noMap: !hasWorkshopMap()})}>
            {hasWorkshopMap() ? <Map {...props} /> : null}
            <Presentation {...props} />
        </div>
        ); 
};
export default TechnicanInfo;