import React from "react";
import { useTranslation } from 'react-i18next';
import ReturnToStart from "../returnToStart"

const FetchError = (props) => {
    const t = useTranslation()[0];

    if (!props.technician || !props.technician.fetchError) {
        return null;
    }
    return (
        <div>
            <div className="technicianNotFoundMessage">
                <h1>{t("fetchError")}</h1>
            </div>
            <ReturnToStart />
        </div>        
        ); 
};
export default FetchError;